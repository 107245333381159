import React from 'react';
import defaultTitle from '../../rules/defaultTitle';
import { PageView, initGA } from '../../components/Tracking';
import {
  Container,
  WrapperTitle,
  WrapperContent,
  WrapperInfo,
  ProjectTitle,
  TitleLeft,
  ProjectInfoContainer,
  ProjectInfoWrapper,
  InfoTextRight,
  InfoDataRight,
  CriteriaRow,
  DataCriteria,
  OneImageRow,
  Image,
  TwoImagesRow,
  HalfImage,
  InlineLink,
} from './Styling';
import DesignFooter from '../../components/DesignFooter';

import playBillboard from '../../img/play_billboard.jpg';
import play3pile from '../../img/play_3pile.jpg';
import play3aligned from '../../img/play_3aligned.jpg';
import play3fan from '../../img/play_3fan.jpg';
import play_sim_hands from '../../img/play_sim_hands.jpg';
import play_sim_out from '../../img/play_sim_out.jpg';
import play_sim_in from '../../img/play_sim_in.jpg';
import play_sim from '../../img/play_sim.jpg';
import play_agenda from '../../img/play_agenda.jpg';
import play_bus_stop from '../../img/play_bus_stop.jpg';
import play_street from '../../img/play_street.jpg';
import play_wall_poster from '../../img/play_wall_poster.jpg';
import play_brick_wall from '../../img/play_brick_wall.jpg';

function Play() {
  document.title = defaultTitle + "Play";
  window.scrollTo(0, 0);
  initGA();
  PageView();
  return (
    <Container>
      <WrapperTitle>
        <ProjectTitle>
          <TitleLeft>
            Play
          </TitleLeft>
        </ProjectTitle>
      </WrapperTitle>
      <WrapperInfo>
        <ProjectInfoContainer>
          <ProjectInfoWrapper>
            <InfoTextRight>
              Designed as a sub-brand of <InlineLink to="/design/hellow">Hellow</InlineLink> and aiming a specific market segment, Play was meant to be the affordable student plan, including unlimited Internet, calls and messages.
            </InfoTextRight>
            <InfoDataRight>
              <CriteriaRow>
                <DataCriteria>Date</DataCriteria>September 2019
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>Location</DataCriteria>Amsterdam, NL
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>ROLE</DataCriteria>Designer
              </CriteriaRow>
            </InfoDataRight>
          </ProjectInfoWrapper>
        </ProjectInfoContainer>
      </WrapperInfo>
      <WrapperContent>
        <OneImageRow>
          <Image src={playBillboard} alt="PLAY billboard" />
        </OneImageRow>
        <OneImageRow>
          <Image src={play3pile} alt="3 PLAY packs pilled up" />
        </OneImageRow>
        <OneImageRow>
          <Image src={play3aligned} alt="3 PLAY packs alligned" />
        </OneImageRow>
        <OneImageRow>
          <Image src={play3fan} alt="3 PLAY packs fan" />
        </OneImageRow>
        <OneImageRow>
          <Image src={play_sim_hands} alt="Hands holding a PLAY pack" />
        </OneImageRow>
        <TwoImagesRow>
          <HalfImage src={play_sim_out} alt="PLAY SIM card popped out" />
          <HalfImage src={play_sim_in} alt="Unbroken PLAY SIM card" />
        </TwoImagesRow>
        <OneImageRow>
          <Image src={play_sim} alt="PLAY SIM card" />
        </OneImageRow>
        <OneImageRow>
          <Image src={play_agenda} alt="PLAY agenda" />
        </OneImageRow>
        <OneImageRow>
          <Image src={play_bus_stop} alt="PLAY ad in a bus stop" />
        </OneImageRow>
        <OneImageRow>
          <Image src={play_street} alt="PLAY ad on a street" />
        </OneImageRow>
        <OneImageRow>
          <Image src={play_wall_poster} alt="PLAY ad on a wall" />
        </OneImageRow>
        <OneImageRow>
          <Image src={play_brick_wall} alt="PLAY logo on a brick wall" />
        </OneImageRow>
        <DesignFooter/>
      </WrapperContent>
    </Container>
  );
}

export default Play;
