import React from 'react';
import defaultTitle from '../../rules/defaultTitle';
import { PageView, initGA } from '../../components/Tracking';
import {
  Container,
  WrapperTitle,
  WrapperContent,
  WrapperInfo,
  ProjectTitle,
  TitleLeft,
  ProjectInfoContainer,
  ProjectInfoWrapper,
  ProjectInfoImages,
  InfoTextRight,
  InfoDataRight,
  CriteriaRow,
  DataCriteria,
  InfoImage,
  OneImageRow,
  Image,
} from './Styling';
import DesignFooter from '../../components/DesignFooter';

import symbolLogo from '../../img/honuka_symbol_logo.png';
import blackLogo from '../../img/honuka_white_bg_logo.png';
import whiteLogo from '../../img/honuka_black_bg_logo.png';

import flyingBoxes from '../../img/honuka_flying_boxes.jpg';
import twoBoxes from '../../img/honuka_2boxes.jpg';
import jar from '../../img/honuka_jar.jpg';

function Honuka() {
  document.title = defaultTitle + "Honuka";
  window.scrollTo(0, 0);
  initGA();
  PageView();
  return (
    <Container>
      <WrapperTitle>
        <ProjectTitle>
          <TitleLeft>
            Honuka
          </TitleLeft>
        </ProjectTitle>
      </WrapperTitle>
      <WrapperInfo>
        <ProjectInfoContainer>
          <ProjectInfoWrapper>
            <InfoTextRight>
              I had multiple assignments during Graphic Design studies. One of them was creating a new brand and packing for New Zealand's unique manuka honey. I was happy to accept the challenge.
            </InfoTextRight>
            <InfoDataRight>
              <CriteriaRow>
                <DataCriteria>Date</DataCriteria>May 2019
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>Location</DataCriteria>Amsterdam, NL
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>ROLE</DataCriteria>Designer
              </CriteriaRow>
            </InfoDataRight>
          </ProjectInfoWrapper>
          <ProjectInfoImages>
            <InfoImage src={blackLogo} alt="Honuka logo on white background" />
            <InfoImage src={whiteLogo} alt="Honuka logo on black background" />
            <InfoImage src={symbolLogo} alt="Honuka symbol logo" />
          </ProjectInfoImages>
        </ProjectInfoContainer>
      </WrapperInfo>
      <WrapperContent>
        <OneImageRow>
          <Image src={flyingBoxes} alt="Honuka packages in the air" />
        </OneImageRow>
        <OneImageRow>
          <Image src={twoBoxes} alt="2 Honuka packages" />
        </OneImageRow>
        <OneImageRow>
          <Image src={jar} alt="Honuka jar" />
        </OneImageRow>
        <DesignFooter/>
      </WrapperContent>
    </Container>
  );
}

export default Honuka;
