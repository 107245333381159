import React from 'react';
import defaultTitle from '../../rules/defaultTitle';
import { PageView, initGA } from '../../components/Tracking';
import {
  Container,
  WrapperTitle,
  WrapperContent,
  WrapperInfo,
  ProjectTitle,
  TitleLeft,
  ProjectInfoContainer,
  ProjectInfoWrapper,
  ProjectInfoImages,
  InfoTextRight,
  InfoDataRight,
  CriteriaRow,
  DataCriteria,
  InfoImage,
  OneImageRow,
  Image,
  TwoImagesRow,
  HalfImage,
} from './Styling';
import DesignFooter from '../../components/DesignFooter';

import logo1 from '../../img/klim_1.png';
import logo2 from '../../img/klim_2.png';
import logo3 from '../../img/klim_3.png';

import klim01 from '../../img/klim_0.1.jpg';
import klim01_2 from '../../img/klim_0.1-2.jpg';
import klim28 from '../../img/klim_2.8.jpg';
import klim28_2 from '../../img/klim_2.8-2.jpg';
import klim36 from '../../img/klim_3.6.jpg';
import klimAll from '../../img/klim_all.jpg';

function Klim() {
  document.title = defaultTitle + "Klim";
  window.scrollTo(0, 0);
  initGA();
  PageView();
  return (
    <Container>
      <WrapperTitle>
        <ProjectTitle>
          <TitleLeft>
            Klim
          </TitleLeft>
        </ProjectTitle>
      </WrapperTitle>
      <WrapperInfo>
        <ProjectInfoContainer>
          <ProjectInfoWrapper>
            <InfoTextRight>
              During my Image Design classes, we faced a challenge to create some unique, different milk cartons compared the usual ones you find on shelves. This below, was my approach for each type.
            </InfoTextRight>
            <InfoDataRight>
              <CriteriaRow>
                <DataCriteria>Date</DataCriteria>April 2019
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>Location</DataCriteria>Amsterdam, NL
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>ROLE</DataCriteria>Designer
              </CriteriaRow>
            </InfoDataRight>
          </ProjectInfoWrapper>
          <ProjectInfoImages>
            <InfoImage src={logo1} alt="Klim logo for 0.1 milk" />
            <InfoImage src={logo2} alt="Klim logo for 2.8 milk" />
            <InfoImage src={logo3} alt="Klim logo for 3.6 milk" />
          </ProjectInfoImages>
        </ProjectInfoContainer>
      </WrapperInfo>
      <WrapperContent>
        <OneImageRow>
          <Image src={klim01_2} alt="Klim cartons for 0.1" />
        </OneImageRow>
        <OneImageRow>
          <Image src={klim28_2} alt="Klim cartons for 2.8" />
        </OneImageRow>
        <TwoImagesRow>
          <HalfImage src={klim01} alt="Klim big carton for 0.1" />
          <HalfImage src={klim28} alt="Klim big carton for 0.1" />
        </TwoImagesRow>
        <OneImageRow>
          <Image src={klim36} alt="Klim cartons for 3.6" />
        </OneImageRow>
        <OneImageRow>
          <Image src={klimAll} alt="The entire Klim product line" />
        </OneImageRow>
        <DesignFooter/>
      </WrapperContent>
    </Container>
  );
}

export default Klim;
