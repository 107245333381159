import React from 'react';
import defaultTitle from '../../rules/defaultTitle';
import { PageView, initGA } from '../../components/Tracking';
import {
  Container,
  WrapperTitle,
  WrapperContent,
  WrapperInfo,
  ProjectTitle,
  TitleLeft,
  ProjectInfoContainer,
  ProjectInfoWrapper,
  ProjectInfoImages,
  InfoTextRight,
  InfoDataRight,
  CriteriaRow,
  DataCriteria,
  InfoImage,
  OneImageRow,
  Image,
  TwoImagesRow,
  HalfImage,
  UrlLink,
} from './Styling';
import DesignFooter from '../../components/DesignFooter';

import white from '../../img/pannor_color_on_white.png';
import black from '../../img/pannor_white_on_black.png';
import color from '../../img/pannor_white_on_color.png';

import shop from '../../img/Pannor_Shop-small.jpg';
import imac from '../../img/imac_my_pannor.jpg';
import blackStore from '../../img/pannor_black_store_front.jpg';
import paper from '../../img/pannor_logo_paper.jpg';
import whiteBag from '../../img/pannor_bag_white.jpg';
import blackBag from '../../img/pannor_bag_black.jpg';
import prepaid from '../../img/pannor_pay_as_you_go_pack.jpg';
import topup20 from '../../img/pannor_topup20_pack.jpg';
import watch from '../../img/pannor_watch.jpg';
import myPannor from '../../img/my_pannor_signin_screen.jpg';
import twoPosters from '../../img/pannor_2posters_unlimited_x_re.jpg';
import mobileWebsite from '../../img/pannor_mobile_website.jpg';
import businessCards from '../../img/pannor_business_cards.jpg';

function Pannor() {
  document.title = defaultTitle + "Pannor";
  window.scrollTo(0, 0);
  initGA();
  PageView();
  return (
    <Container>
      <WrapperTitle>
        <ProjectTitle>
          <TitleLeft>
            Pannor
          </TitleLeft>
        </ProjectTitle>
      </WrapperTitle>
      <WrapperInfo>
        <ProjectInfoContainer>
          <ProjectInfoWrapper>
            <InfoTextRight>
              A perfect combination of Graphic Design, Web Development and my endless passion for the telecommunications industry. This is what this project is all about, and that's how Pannor was born - from logo to a functional corporate website.
            </InfoTextRight>
            <InfoDataRight>
              <CriteriaRow>
                <DataCriteria>Date</DataCriteria>July 2020
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>Location</DataCriteria>Amsterdam, NL
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>ROLE</DataCriteria>Designer, Developer
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>URL</DataCriteria>
                <UrlLink to={{ pathname: "https://www.pannor.nl" }} target="_blank">
                  www.pannor.nl
                </UrlLink>
              </CriteriaRow>
            </InfoDataRight>
          </ProjectInfoWrapper>
          <ProjectInfoImages>
            <InfoImage src={white} alt="Pannor logo on white background" />
            <InfoImage src={black} alt="Pannor logo on black background" />
            <InfoImage src={color} alt="Pannor logo on color background" />
          </ProjectInfoImages>
        </ProjectInfoContainer>
      </WrapperInfo>
      <WrapperContent>
        <OneImageRow>
          <Image src={shop} alt="Pannor mall shop" />
        </OneImageRow>
        <TwoImagesRow>
          <HalfImage src={blackStore} alt="Pannor store" />
          <HalfImage src={paper} alt="Pannor logo on carton" />
        </TwoImagesRow>
        <OneImageRow>
          <Image src={prepaid} alt="Pannor Pay As You Go pack" />
        </OneImageRow>
        <TwoImagesRow>
          <HalfImage src={whiteBag} alt="Pannor white bag" />
          <HalfImage src={blackBag} alt="Pannor black bag" />
        </TwoImagesRow>
        <OneImageRow>
          <Image src={businessCards} alt="Pannor business cards" />
        </OneImageRow>
        <OneImageRow>
          <Image src={mobileWebsite} alt="Pannor website viewed on an iPhone" />
        </OneImageRow>
        <OneImageRow>
          <Image src={topup20} alt="Pannor 20 EUR top-up card" />
        </OneImageRow>
        <OneImageRow>
          <Image src={watch} alt="Pannor eSIM on Apple Watch" />
        </OneImageRow>
        <OneImageRow>
          <Image src={twoPosters} alt="Pannor Unlimited X ad on 2 posters" />
        </OneImageRow>
        <OneImageRow>
          <Image src={myPannor} alt="My Pannor app" />
        </OneImageRow>
        <OneImageRow>
          <Image src={imac} alt="My Pannor website preview" />
        </OneImageRow>
        <DesignFooter/>
      </WrapperContent>
    </Container>
  );
}

export default Pannor;
