import React from 'react';
import defaultTitle from '../../rules/defaultTitle';
import { PageView, initGA } from '../../components/Tracking';
import {
  Container,
  WrapperTitle,
  WrapperContent,
  WrapperInfo,
  ProjectTitle,
  TitleLeft,
  ProjectInfoContainer,
  ProjectInfoWrapper,
  ProjectInfoImages,
  InfoTextRight,
  InfoDataRight,
  CriteriaRow,
  DataCriteria,
  InfoImage,
  OneImageRow,
  Image,
  TwoImagesRow,
  HalfImage,
} from './Styling';
import DesignFooter from '../../components/DesignFooter';

import hellowYellow from '../../img/hellow-square_logo-yellow_bg.png';
import hellowWhite from '../../img/hellow-square_logo-white_bg.png';
import hellowBlack from '../../img/hellow-square_logo-black_bg.png';

import hellow_shop from '../../img/stylish-shop_color.jpg';
import hellow_shop_banner from '../../img/hellow_shop-banner.jpg';
import hellow_partner from '../../img/hellow-partner.jpg';
import yellowTshirt from '../../img/hellow_t_shirt_amber.jpg';
import yellowTshirtGuy from '../../img/hellow_t_shirt_yellow_black_bg.jpg';
import blackTshirt from '../../img/hellow_t_shirt_black.jpg';
import blackTshirtGuy from '../../img/hellow_t_shirt_black_brick_bg.jpg';
import techGuy from '../../img/hellow_tech_guy.jpg';
import sedan from '../../img/hellow_sedan.png';
import coupe from '../../img/hellow_2seat_car.png';
import stamp from '../../img/hellow_stamp.jpg';
import invoice from '../../img/hellow_invoice.jpg';
import postpaidSimFace from '../../img/hellow_amber_sim_face.png';
import postpaidSimBack from '../../img/hellow_amber_sim_back.png';
import prepaidSimFace from '../../img/hellow_light_sim_face.png';
import prepaidSimBack from '../../img/hellow_light_sim_back.png';
import businessSimFace from '../../img/hellow_black_sim_face.png';
import businessSimBack from '../../img/hellow_black_sim_back.png';
import prepaidPack from '../../img/hellow_prepaid_pack.jpg';
import bag from '../../img/hellow_bag.jpg';
import mug from '../../img/hellow_mug.png';
import cups from '../../img/hellow_cups.png';
import boxTshirt from '../../img/hellow_box_tshirt.jpg';
import magazine from '../../img/hellow_magazine_ad.jpg';
import busStop from '../../img/hellow_bus_stop_rembrandtplein.jpg';
import internetHome from '../../img/hellow_internet_home_banner.jpg';
import lampPost from '../../img/hellow_lamp_post_banner.jpg';
import brickWall from '../../img/hellow_brick_wall.jpg';

function Hellow() {
  document.title = defaultTitle + "Hellow";
  window.scrollTo(0, 0);
  initGA();
  PageView();
  return (
    <Container>
      <WrapperTitle>
        <ProjectTitle>
          <TitleLeft>
            Hellow
          </TitleLeft>
        </ProjectTitle>
      </WrapperTitle>
      <WrapperInfo>
        <ProjectInfoContainer>
          <ProjectInfoWrapper>
            <InfoTextRight>
              Back when I was still studying Graphic Design, I've chosen to create a complete new brand as my final project. I've designed Hellow as the new unlimited carrier, which stands for simplicity and straightforwardness.
            </InfoTextRight>
            <InfoDataRight>
              <CriteriaRow>
                <DataCriteria>Date</DataCriteria>September 2019
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>Location</DataCriteria>Amsterdam, NL
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>ROLE</DataCriteria>Designer
              </CriteriaRow>
            </InfoDataRight>
          </ProjectInfoWrapper>
          <ProjectInfoImages>
            <InfoImage src={hellowYellow} alt="Hellow logo on yellow background" />
            <InfoImage src={hellowWhite} alt="Hellow logo on white background" />
            <InfoImage src={hellowBlack} alt="Hellow logo on black background" />
          </ProjectInfoImages>
        </ProjectInfoContainer>
      </WrapperInfo>
      <WrapperContent>
        <OneImageRow>
          <Image src={hellow_shop} alt="Hellow mall shop" />
        </OneImageRow>
        <TwoImagesRow>
          <HalfImage src={hellow_shop_banner} alt="Hellow street shop" />
          <HalfImage src={hellow_partner} alt="Hellow partner sign" />
        </TwoImagesRow>
        <OneImageRow>
          <Image src={yellowTshirtGuy} alt="Hellow amber T-shirt on a male model" />
        </OneImageRow>
        <OneImageRow>
          <Image src={blackTshirtGuy} alt="Hellow black T-shirt on a male model" />
        </OneImageRow>
        <TwoImagesRow>
          <HalfImage src={yellowTshirt} alt="Hellow amber T-shirt" />
          <HalfImage src={blackTshirt} alt="Hellow black T-shirt" />
        </TwoImagesRow>
        <OneImageRow>
          <Image src={techGuy} alt="Hellow Technical Support T-shirt" />
        </OneImageRow>
        <TwoImagesRow>
          <HalfImage src={sedan} alt="Hellow Technical 4-seat car" />
          <HalfImage src={coupe} alt="Hellow Technical 2-seat car" />
        </TwoImagesRow>
        <OneImageRow>
          <Image src={stamp} alt="Hellow stamp" />
        </OneImageRow>
        <OneImageRow>
          <Image src={invoice} alt="Hellow invoice" />
        </OneImageRow>
        <TwoImagesRow>
          <HalfImage src={postpaidSimBack} alt="Hellow postpaid SIM back" />
          <HalfImage src={postpaidSimFace} alt="Hellow postpaid SIM face" />
        </TwoImagesRow>
        <TwoImagesRow>
          <HalfImage src={prepaidSimFace} alt="Hellow prepaid SIM face" />
          <HalfImage src={prepaidSimBack} alt="Hellow prepaid SIM back" />
        </TwoImagesRow>
        <TwoImagesRow>
          <HalfImage src={businessSimBack} alt="Hellow business SIM back" />
          <HalfImage src={businessSimFace} alt="Hellow business SIM face" />
        </TwoImagesRow>
        <OneImageRow>
          <Image src={prepaidPack} alt="Hellow prepaid pack" />
        </OneImageRow>
        <OneImageRow>
          <Image src={bag} alt="Hellow bag" />
        </OneImageRow>
        <TwoImagesRow>
          <HalfImage src={mug} alt="Hellow mug" />
          <HalfImage src={cups} alt="Hellow cups" />
        </TwoImagesRow>
        <OneImageRow>
          <Image src={boxTshirt} alt="Hellow Thank You T-shirt" />
        </OneImageRow>
        <OneImageRow>
          <Image src={magazine} alt="My Hellow ad on the back cover of a magazine" />
        </OneImageRow>
        <OneImageRow>
          <Image src={busStop} alt="My Hellow ad on a bus stop" />
        </OneImageRow>
        <OneImageRow>
          <Image src={internetHome} alt="Hellow Internet @home banner in a shopping mall" />
        </OneImageRow>
        <OneImageRow>
          <Image src={lampPost} alt="Hellow - launch campaign, on a lamp post" />
        </OneImageRow>
        <OneImageRow>
          <Image src={brickWall} alt="Hellow logo on a brick wall" />
        </OneImageRow>
        <DesignFooter/>
      </WrapperContent>
    </Container>
  );
}

export default Hellow;
