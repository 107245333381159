import React from 'react';
import defaultTitle from '../../rules/defaultTitle';
import { PageView, initGA } from '../../components/Tracking';
import {
  Container,
  WrapperTitle,
  WrapperContent,
  WrapperInfo,
  ProjectTitle,
  TitleLeft,
  ProjectInfoContainer,
  ProjectInfoWrapper,
  InfoTextRight,
  InfoDataRight,
  CriteriaRow,
  DataCriteria,
  OneImageRow,
  Image,
  TwoImagesRow,
  HalfImage,
} from './Styling';
import DesignFooter from '../../components/DesignFooter';

import creation from '../../img/flamingo_creation.png';
import logo1 from '../../img/flamingo_1.png';
import logo2 from '../../img/flamingo_2.png';
import flag from '../../img/flamingo_flag.jpg';

function Flamingo() {
  document.title = defaultTitle + "Flamingo";
  window.scrollTo(0, 0);
  initGA();
  PageView();
  return (
    <Container>
      <WrapperTitle>
        <ProjectTitle>
          <TitleLeft>
            Flamingo
          </TitleLeft>
        </ProjectTitle>
      </WrapperTitle>
      <WrapperInfo>
        <ProjectInfoContainer>
          <ProjectInfoWrapper>
            <InfoTextRight>
              For this project, my assignment was simple - to create a "flamingo" logo for a purpose of my own choice. I dived right in and created a clean, minimalist logo for a beach resort.
            </InfoTextRight>
            <InfoDataRight>
              <CriteriaRow>
                <DataCriteria>Date</DataCriteria>April 2019
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>Location</DataCriteria>Amsterdam, NL
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>ROLE</DataCriteria>Designer
              </CriteriaRow>
            </InfoDataRight>
          </ProjectInfoWrapper>
        </ProjectInfoContainer>
      </WrapperInfo>
      <WrapperContent>
        <OneImageRow>
          <Image src={creation} alt="Flamingo - Logo creation process" />
        </OneImageRow>
        <TwoImagesRow>
          <HalfImage src={logo1} alt="Flamingo logo on light background" />
          <HalfImage src={logo2} alt="Flamingo logo on dark background" />
        </TwoImagesRow>
        <OneImageRow>
          <Image src={flag} alt="Flamingo logo on flag" />
        </OneImageRow>
        <DesignFooter/>
      </WrapperContent>
    </Container>
  );
}

export default Flamingo;
